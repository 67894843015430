.chip {
    padding: 4px 10px;
    background: #0096fb !important;
    margin-right: 5px;
    margin-bottom: 5px;
    border-radius: 11px;
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    line-height: 19px;
    color: #fff !important;
}

.chip, .singleChip {
    white-space: normal !important;
}