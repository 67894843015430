/*.loader-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f8f8f8ad;   
   }*/
.loader-container-modal {
  /*position: absolute;*/
  border-radius: 0.3rem;
  outline: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 15rem;
  background-color: #fff;
}
.loader-modal {
  left: 40%;
  top: 22%;
  z-index: 1000;
  position: absolute;
}
