.switch {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
	input {
		opacity: 0;
		width: 0;
		height: 0;
	}
}
.slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	transition: 0.4s;
	&::before {
		position: absolute;
		content: "";
		height: 26px;
		width: 26px;
		left: 4px;
		bottom: 4px;
		background-color: white;
		transition: 0.4s;
	}
}
input {
	&:checked {
		+ {
			.slider {
				background-color: #192847;
				&::before {
					transform: translateX(26px);
				}
			}
		}
	}
}
