.row.react-bootstrap-table-pagination{
    width: 100%;
}
.react-bootstrap-table-pagination-list{
    padding-left: 10px;
    padding-bottom: 0;
    padding-top: 10px;
    padding-right: 0;
}

.react-bs-table-sizePerPage-dropdown.dropdown #pageDropDown[aria-expanded="true"]{
    background-color: #6c757d !important;
    border-color: #6c757d;
}

.form-datePicker{
    border: 1px solid #ced4da;
    padding: .375rem .75rem;
    border-radius: .25rem;
}

form#formPrediccionHeladas > .singleChip{
    overflow: hidden;
    display: list-item;
}

.maxcustom > .nice-dates >.nice-dates-popover {
    max-width: 300px!important;
    min-width: 300px !important;
}

.maxcustom > .nice-dates >.nice-dates-popover>div>.nice-dates-grid>.nice-dates-grid_container>.nice-dates-day {
    min-height: 44px !important;
}

.maxcustom > .nice-dates >.nice-dates-popover>div>.nice-dates-grid {
    min-height: 300px !important;
}