.loader-container{
 position: fixed;
 top: 0;
 left: 0;
 width: 100%;
 height: 100%;
 background-color: #f8f8f8ad;   
 z-index: 9999;
}
.loader{
    left: 45%;
    top: 40%;
    z-index: 99999;
    position: absolute;
}