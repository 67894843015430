#pbiAppPlaceHolder{
    background-color: red !important; 
}

.position-static[_nghost-gjq-c157]   .notification-bar[_ngcontent-gjq-c157]{
    position:initial
}
.notification-bar[_ngcontent-gjq-c157]{
    display:flex;
    justify-content:space-between;
    background:var(--neutral-secondary-alt-color, #970404) !important;
    width:auto;
    height:32px;
    position:absolute;
    top:40px;
    bottom:0;
    left:0;
    right:0;
    z-index:59;
    border-bottom:1px solid var(--fluent-theme-neutral-quarter-alt-color, #E1DFDD);}
    @keyframes infiniteLoading {
        0%{
            left:-200px;
            width:30%
        }
        50%{
            width:30%
        }
        70%{
            width:70%
        }
        80%{
            left:50%
        }
        95%{
            left:120%
        }
        to{
            left:100%
        }
    }
    @keyframes progressBarState1{
        0%{
            width:0%
        }
        to{
            width:90%
        }
    }
    @keyframes progressBarState2{
        0%{
            width:90%
        }
        to{
            width:95%
        }
    }
    @keyframes progressBarState3{
        0%{
            width:95%
        }
        to{
            width:98%
        }
    }
    @keyframes progressBarState4{
        0%{
            width:98%
        }
        to{
            width:100%
        }
    }
    .notification-bar[_ngcontent-gjq-c157]   .infiniteLoader[_ngcontent-gjq-c157]{
        height:6px;
        width:200px;
        position:relative;
        overflow:hidden;
        background-color:var(--white-color, #FFF);
        float:left;
        top:22px;
    }.notification-bar[_ngcontent-gjq-c157]   .infiniteLoader[_ngcontent-gjq-c157]:before{
        display:block;
        position:absolute;
        content:"";
        left:-200px;
        width:200px;
        height:6px;
        background-color:var(--theme-primary-color, #F2C811);
        animation:infiniteLoading 2s linear infinite;
    }.notification-bar[_ngcontent-gjq-c157]   .progress-bar-loader[_ngcontent-gjq-c157]{
        width:100%;
        height:2px;
        display:block;
        background-color:var(--theme-primary-color, #F2C811);
        animation-name:progressBarState1,progressBarState2,progressBarState3,progressBarState4;
        animation-timing-function:linear;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]{
        display:flex;
        padding:0 8px;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button[_ngcontent-gjq-c157]{
        display:block;height:24px;margin:4px 0;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button.primary[_ngcontent-gjq-c157]{
        padding:2px 16px;background-color:var(--white-color, #FFF);border:1px solid var(--gray-110, #8A8886);box-sizing:border-box;border-radius:2px;
    }
    html:not([dir="rtl"])[_nghost-gjq-c157]   .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button.primary[_ngcontent-gjq-c157], html:not([dir="rtl"])   [_nghost-gjq-c157]   .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button.primary[_ngcontent-gjq-c157]{
        margin-left:8px;
    }
    html[dir="rtl"][_nghost-gjq-c157]   .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button.primary[_ngcontent-gjq-c157], html[dir="rtl"]   [_nghost-gjq-c157]   .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button.primary[_ngcontent-gjq-c157]{
        margin-right:8px;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button.last[_ngcontent-gjq-c157]{margin-right:0}.notification-bar[_ngcontent-gjq-c157]   .bar-actions[_ngcontent-gjq-c157]   button.dismissBtn[_ngcontent-gjq-c157]{
        background:none;
        border:none;
        margin-right:0;
        margin-left:8px;
        color:var(--fluent-theme-neutral-secondary-color, #ee7f10);
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]{
        display:flex;
        align-items:center;
        flex-grow:1;
        box-sizing:border-box;
        padding:0 8px;
        line-height:32px;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .icon[_ngcontent-gjq-c157], .notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   spinner[_ngcontent-gjq-c157]{
        color:var(--fluent-theme-neutral-secondary-color, #11db1b);
        box-sizing:border-box;
        padding-right:8px;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .powerbi-spinner[_ngcontent-gjq-c157]   .spinner[_ngcontent-gjq-c157]   .circle[_ngcontent-gjq-c157]:after{background:var(--white-color, #FFF)!important}.notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .image-icon[_ngcontent-gjq-c157]{
        vertical-align:middle;
        height:21px;
        width:21px;
        margin-right:8px;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .message[_ngcontent-gjq-c157]{
        color:var(--fluent-theme-neutral-primary-color, #323130);
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        display:block;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   a.action-link[_ngcontent-gjq-c157]{
        padding:0 8px;
        color:var(--fluent-theme-link-darker-color, #005A9E);
        text-decoration:underline;
        cursor:pointer;
    }
    .notification-bar[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .embedded-action-link[_ngcontent-gjq-c157]{
        padding:0;
    }
    .notification-bar.success[_ngcontent-gjq-c157]{
        background-color:var(--fluent-theme-success-background-color, #DFF6DD);
    }
    .notification-bar.error[_ngcontent-gjq-c157]{
        background-color:var(--fluent-theme-error-background-color, #FDE7E9);
    }
    .notification-bar.info[_ngcontent-gjq-c157]{
        background-color:var(--fluent-theme-neutral-lighter-color, #F3F2F1);
    }
    .notification-bar.warning[_ngcontent-gjq-c157], .notification-bar.sample-data-load[_ngcontent-gjq-c157]{
        background-color:var(--fluent-theme-warning-background-color, #FFF4CE);
    }
    .notification-bar.sample-data-load[_ngcontent-gjq-c157]   .progress-bar-loader[_ngcontent-gjq-c157]{
        display:none;
        background-color:unset;
    }
    .notification-bar.teaching[_ngcontent-mle-c157]{
        height:40px;
        align-items:center;
        background-color:var(--fluent-theme-neutral-secondary-color, #cc135a);
        color:var(--fluent-theme-white-color, #FFF);
        display:flex;
    }
    .notification-bar.teaching[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .message-wrapper[_ngcontent-gjq-c157]{
        display:inline;
        margin:0 4px;
    }
    .notification-bar.teaching[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .message-wrapper[_ngcontent-gjq-c157]   .message[_ngcontent-gjq-c157]{
        display:inline;
        color:var(--fluent-theme-white-color, #FFF);
        font-size:var(--md-font-size, 14px);
    }
    .notification-bar.teaching[_ngcontent-gjq-c157]   .bar-content[_ngcontent-gjq-c157]   .message-wrapper[_ngcontent-gjq-c157]   .message[_ngcontent-gjq-c157]   a[_ngcontent-gjq-c157]{
        color:var(--fluent-theme-white-color, #FFF);
    }
    @media print{body   div.notification-bar[_nghost-gjq-c157], body   div.notification-bar   [_nghost-gjq-c157]{
        display:none!important;
    }
}