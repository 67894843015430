.button-calculadora-rounded {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 20px;
  border: none;
  border-radius: 50%;
  padding: 7px;
  width: 53px;
  height: 53px;
  margin-bottom: 10px;
  margin-right: 30px;
  color: white;
  background-color: #192847;
  font-weight: bold;
}
.borrar{
  background-color: rgb(143, 13, 54) !important;
}
.button-calculadora-long {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-size: 27px;
  border: none;
  border-radius: 41px;
  padding: 0;
  width: 99px;
  height: 41px;
  margin-bottom: 10px;
  margin-right: 10px;
  color: #fff;
  background-color: #0d6efd;
  background-image: none;
  opacity: 1;
  font-weight: bold;
}
.centrar-buttons {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
