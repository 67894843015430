$color-gray-dark: #0e3254;
$color-gray: #818d98;
$color-gray-light: #d3dde6;
$color-gray-lighter: #f6f8fa;
$color-accent: #438cd2;

$font-size-small: 14px;
$font-size: 17px;
$font-size-big: 21px;

$nice-dates-color-gray-dark: $color-gray-dark;
$nice-dates-color-gray: $color-gray;
$nice-dates-color-gray-light: $color-gray-light;
$nice-dates-color-accent: $color-accent;

@import './styles';

.inputs {
    -webkit-appearance: none;
    background-color: #fff;
    border-radius: 6px;
    border: 1px solid $color-gray-light;
    color: $color-gray-dark;
    display: block;
    font-family: inherit;
    font-size: inherit;
    height: 42px;
    outline: none;
    padding: 0 12px;
    transition: border-color 200ms;
    width: 110px;
  
    &:focus, &.-focused {
      border-color: $color-accent;
    }
  
    &::placeholder {
      color: $color-gray-light;
    }
  }

  .date-range {
    display: flex;
    justify-content: space-between;
  
    .input {
      width: 100%;
    }
  
    &_arrow {
      flex-shrink: 0;
      position: relative;
      width: 36px;
  
      &:before {
        border-right: 2px solid $color-gray-light;
        border-top: 2px solid $color-gray-light;
        box-sizing: border-box;
        content: '';
        display: block;
        height: 18px;
        left: 50%;
        margin-left: -14px;
        margin-top: -9px;
        position: absolute;
        top: 50%;
        transform: rotate(45deg);
        width: 18px;
      }
    }
  }  