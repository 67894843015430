.sidebar-root{
    display: flex;
    flex-direction: column;
    width: 320px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    padding-left: 16px;
    background-color: #fff;
    overflow-y: auto;
    transition: height 1s;
    transform: translateX(-320px);
  
    @media print {
      display: none;
    }
  
    :global(.sidebar-hide) & {
      display: none;
    }

    @media (max-width: 768px) {
      position: static !important;
      width: auto;
      margin: 12px 24px 0;
      border-radius: 8px;
      padding: 0;
      height: 0;
      transform: none;
  
      .logo,
      .sidebarAlerts,
      .sidebarLabels,
      .navTitle {
        display: none;
      }
    }
  }
  
  .logo {
    display: flex;
    align-items: center;
    margin: 16px 0 24px;
    width: 100%;
  
    .title {
      margin-left: 12px;
      font-size: 20px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .valid-message{
    color: red;
  }
  .valid-toggle{
    background-color: red;
  }