.icon_down_dir{
    display: none !important;
}
.chip, .singleChip {
    white-space: normal !important;
}

.span-variables {
    font-size: 12px;
    font-weight: 900;
    display: flex;
    margin-top: 7px;
}
