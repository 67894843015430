.labSitio {
    height: 100%;
    position: relative;
    left: 0;
    transition: left 0.2 ease-in-out;
  }
  
  .wrap {
    position: relative;
    min-height: 100vh;
    display: flex;
    margin-left: 320px;
    flex-direction: column;
    right: 0;
    left: 0;
    transition: left 0.2 ease-in-out, margin-left 0.2 ease-in-out;
  
    @media print {
      margin: 0;
    }
  
    @media (max-width: 768px) {
      margin-left: 0;
      left: 0;
    }
    :global(.sidebar-hide) & {
      margin: 0;
    }
  }
  
  .menu-filtro {
    @media (min-width: 768.02px) {
      margin-left: 0;
      left: 0;
    }
  }
  
  .content {
    position: relative;
    flex-grow: 1;
    padding: 24px 24px 68px;
    background-color: #fff;
  }
  
  .message-lab {
    display: flex;
    text-align: center;
  }
  .mensaje{
    font-size: 28px;
  }