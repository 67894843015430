.containerDisplay{
    height: 100%;
    padding: 0% 8%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: #e0e6e577;
    border-radius: 10px
}


.numeros{
    font-weight: bold;
    text-align: right;
    margin-bottom: 5%;
    font-size: 0.90em;
    color: rgba(19, 18, 18, 0.705);
    overflow: auto;
}

.resultado{
    font-weight: bold;
    text-align: right;
    margin-bottom: 5%;
    overflow: auto;
    font-size: 1.5em;
}